import { module as m } from 'modujs';
import Scrollbar from 'smooth-scrollbar';
import gsap from "gsap";
import {throttle} from "lodash";
import {html} from "../../utils/environment";

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.lastY = 0;
        this.isOut = true;

        // DOM
        this.dom = {};
        this.dom.items = this.el.querySelectorAll('.main .menu-item-has-children');
        this.dom.slaveItems = this.el.querySelectorAll('.submenu.has-depth .submenu__list .child-has-depth');
        this.dom.slaveItemsWrapper = this.el.querySelectorAll('.submenu.has-depth .child-has-depth__container .child-has-depth__wrapper');

        // FUNC
        this.scroll = this.scroll.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    init() {
        
        console.log('HEADER INIT');

        // SCROLL
        //window.addEventListener('scroll', throttle(this.scroll, 100));

        // Main menu
        /*this.dom.items.forEach(item => {
            item.addEventListener('mouseenter', () => {
                const _sub = this.el.querySelector('.submenu.sub-' + item.dataset.menuid);
                _sub.classList.add('visible');
            });
            item.addEventListener('mouseleave', () => {
                const _sub = this.el.querySelector('.submenu.sub-' + item.dataset.menuid);
                _sub.classList.remove('visible');
            });
        });*/

        // Slave menu
        this.dom.slaveItems.forEach(item => {
            item.addEventListener('mouseenter', () => {
                const _children = item.parentNode.querySelectorAll('.child-has-depth');
                const _subLists = item.parentNode.parentNode.querySelectorAll('.child-has-depth__wrapper');
                const _index = Array.prototype.indexOf.call(_children, item);
                
                _children.forEach(elt => elt.classList.remove('current'));
                _subLists.forEach(elt => elt.classList.remove('current'));

                item.classList.add('current');
                _subLists[_index].classList.add('current');
            });
        });

        // Scrollbar
        this.dom.slaveItemsWrapper.forEach(item => {
            Scrollbar.init(item);
        });
    }

    /**
     * SCROLL
     */
    scroll() {
        this.direction = window.scrollY >= this.lastY ? 1 : -1;

        if(this.direction === 1 && window.scrollY > 100)
            this.hide();
        else
            this.show();

        this.lastY = window.scrollY;
    }

    /**
     * HIDE
     */
    hide() {
        if(this.isOut) {
            this.isOut = false;
            gsap.to(this.el, {
                duration: 0.6,
                yPercent: -10,
                autoAlpha: 0,
                ease: 'power4.out'
            });
        }
    }

    /**
     * SHOW
     */
    show() {
        if(!this.isOut) {
            this.isOut = true;
            gsap.to(this.el, {
                duration: 0.6,
                yPercent: 0,
                autoAlpha: 1,
                ease: 'power4.out'
            });
        }
    }
}
