import { module as m } from 'modujs';

export default class extends m {
    constructor(m) {
        super(m);
    }

    init() {}
    
    updateNumber(i__args) {
        if(this.$('current')[0]) {
            if(i__args.way === 'enter')
                this.$('current')[0].innerHTML = '0' + i__args.params;
        }
    }
}
