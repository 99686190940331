import { module as m } from 'modujs';

export default class extends m {
    constructor(m) {
        super(m);
    }

    init() {
        if(this.$('video')[0])
            this.$('video')[0].play();
    }
}
