import { module as m } from 'modujs';
import Choices from "choices.js";
import axios from 'axios';
import Qs from 'qs';
import gsap from 'gsap';

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.isFiltering = false;
        this.isProcessing = false;
        this.isFirstLoad = true;
        this.showLoadMoreButton = true;
        this.offset = 0;
        this.filter = "";

        // FUNC
        this._loadProjects = this._loadProjects.bind(this);

        // EVENTS
        this.events = {
            click : {
                button: '_loadMore'
            }
        }

    }

    init() {
        
        console.log('NEWS INIT');

        this.choiceCategories = new Choices("#filter-categories", {
            placeholder: true,
            searchEnabled: false,
            itemSelectText: ''
        });

        this.choiceCategories.passedElement.element.addEventListener('change', () => {
           this.filter = this.choiceCategories.passedElement.element.value;
           this.isFiltering = true;
           this.offset = 0;
           this._loadProjects();
        });

        // LOAD
        this._loadProjects();
    }

    /**
     * START
     */
    start() {
        //this._loadProjects();
    }

    /**
     * DESTROY
     */
    destroy() {}

    /**
     * Reset
     * @private
     */
    _reset() {}

    /**
     * VALID
     * @private
     */
    _loadMore() {
        this._loadProjects();
    }

    /**
     * Load projects
     * @private
     */
    _loadProjects() {
        if(!this.isProcessing) {
            this.isProcessing  = true;

            const _tl = gsap.timeline({
                onComplete: () => {
                    //this.call('update', {}, 'Load');
                    if(this.isFiltering)
                        this.$('container')[0].innerHTML = "";

                    this._processLoading();
                }
            });
            if(this.isFiltering) {
                _tl.to(this.$('wrapper')[0], {
                    duration: 0.6,
                    autoAlpha: 0,
                    height: 0,
                    ease: 'power4.out'
                });
            }
            _tl.to(this.$('button')[0], {
                duration: 0.6,
                autoAlpha: 0,
                ease: 'power4.out'
            }, 0);
            _tl.to(this.$('loader')[0], {
                duration: 0.6,
                autoAlpha: 1,
                ease: 'power4.out'
            }, 0);
        }
    }

    _processLoading() {
        let ajaxData = {
            action: 'ajax_function_manager',
            fn: 'getProjects',
            filter: this.filter,
            offset: this.offset,
            lang: CURRENT_LANG
        };

        axios({
            method:'get',
            url: AJAX_URL + '?' + Qs.stringify( ajaxData ),
            responseType: 'json'
        })
            .then((response) => {
                const parser = new DOMParser();
                const htmlString = response.data.template;
                const doc3 = parser.parseFromString(htmlString, "text/html");

                if(doc3.querySelector('.news-container').dataset.enabledad === "1" && doc3.querySelector('.news-container').dataset.nbitems === "12") {
                    this.showLoadMoreButton = true;
                } else if(doc3.querySelector('.news-container').dataset.enabledad === "0" && doc3.querySelector('.news-container').dataset.nbitems === "14") {
                    this.showLoadMoreButton = true;
                } else {
                    this.showLoadMoreButton = false;
                }

                this.$('container')[0].appendChild(doc3.querySelector('.news-container'));
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(() => {
                const _tl = gsap.timeline({
                    onComplete: () => {
                        this.call('update', {}, 'Load');
                        this.$('wrapper')[0].style.height = "auto";
                        this.isProcessing = false;
                        this.isFirstLoad = false;
                        this.isFiltering = false;
                        this.offset = this.$('container')[0].querySelectorAll('.new-item').length;
                    }
                });
                _tl.to(this.$('loader')[0], {
                    duration: 0.6,
                    autoAlpha: 0,
                    ease: 'power4.out'
                });
                if(this.showLoadMoreButton) {
                    _tl.to(this.$('button')[0], {
                        duration: 0.6,
                        autoAlpha: 1,
                        ease: 'power4.out'
                    }, 0);
                }
                _tl.to(this.$('wrapper')[0], {
                    duration: 1,
                    autoAlpha: 1,
                    height: this.$('container')[0].clientHeight,
                    ease: 'power4.inOut',
                    onComplete: () => {
                        this.$('container')[0].style.height = 'auto';
                    }
                }, 0.3);
            });
    }

}
