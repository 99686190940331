import { module as m } from 'modujs';
import gsap from "gsap";
import lottie from "lottie-web";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.bm = null;
    }

    init() {

        if (this.$('container')[0].dataset.bmid !== "") {

            const _ext = this.$('container')[0].dataset.bmid.substr(this.$('container')[0].dataset.bmid.lastIndexOf(".") + 1);
            console.log(_ext);
            if(_ext === 'json') {
                this.bm = lottie.loadAnimation({
                    container: this.$('container')[0], // the dom element that will contain the animation
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    path: this.$('container')[0].dataset.bmid // the path to the animation json
                });
            } else {
                const _img = document.createElement("img");
                _img.src = this.$('container')[0].dataset.bmid;
                _img.setAttribute('class', 'icon-img');
                this.$('container')[0].appendChild(_img);
            }
        }

    }

    play() {
        if(this.bm)
        this.bm.play();
    }
}
