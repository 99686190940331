import { module as m } from 'modujs';
import gsap from 'gsap';
import {StoreSingleton} from "../../singletons/store";

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.numberOfImages = this.$('image').length;
        this.currentImage = this.$('image')[0];
        this.currentItem = this.$('item')[0];
        this.currentIndex = 0;
        this.prevIndex = 0;
        this.isProcessing = false;

        this.touchstartX = 0;
        this.touchstartY = 0;
        this.touchendX = 0;
        this.touchendY = 0;

        // FUNC
        this._goLeft = this._goLeft.bind(this);
        this._goRight = this._goRight.bind(this);
        this._handleGesture = this._handleGesture.bind(this);

        // EVENTS
        this.events = {
            click: {
                left: '_goLeft',
                right: '_goRight'
            }
        }
    }

    init() {
        if(StoreSingleton.isMobile) {
            this.el.addEventListener('touchstart', (event) => {
                this.touchstartX = event.changedTouches[0].screenX;
                this.touchstartY = event.changedTouches[0].screenY;
            }, false);

            this.el.addEventListener('touchend', (event) => {
                this.touchendX = event.changedTouches[0].screenX;
                this.touchendY = event.changedTouches[0].screenY;
                switch (this._handleGesture(this.touchstartX, this.touchstartY, this.touchendX, this.touchendY)) {
                    case 'left':
                        this._goRight();
                        break;
                    case 'right':
                        this._goLeft();
                        break;
                }
            }, false);
        }
    }

    _goLeft() {
        if(!this.isProcessing) {
            this.isProcessing = true;
            this.prevIndex = this.currentIndex;

            if(this.currentIndex - 1 < 0) {
                this.currentIndex = this.$('item').length - 1;
            } else {
                this.currentIndex--;
            }

            this._doAnimation();
        }
    }

    _goRight() {
        if(!this.isProcessing) {
            this.isProcessing = true;
            this.prevIndex = this.currentIndex;

            if(this.currentIndex + 1 > this.$('item').length - 1) {
                this.currentIndex = 0;
            } else {
                this.currentIndex++;
            }

            this._doAnimation();
        }
    }

    _doAnimation() {
        const _tl = gsap.timeline({
            onComplete: () => {
                this.isProcessing = false;
            }
        })
        _tl.set(this.$('item')[this.currentIndex], {
            yPercent: 10,
            autoAlpha: 0
        });
        _tl.to(this.$('item')[this.prevIndex], {
            duration: 0.8,
            yPercent: -10,
            rotation: 0.1,
            autoAlpha: 0,
            display: 'none',
            ease: 'power4.in'
        });
        _tl.to(this.$('item')[this.currentIndex], {
            duration: 0.8,
            yPercent: 0,
            rotation: 0.1,
            autoAlpha: 1,
            display: 'flex',
            ease: 'power4.out',
        });

        if(this.numberOfImages > 1) {
            _tl.to(this.$('image')[this.prevIndex], {
                duration: 0.8,
                autoAlpha: 0,
                ease: 'power4.in'
            }, 0);
            _tl.to(this.$('image')[this.currentIndex], {
                duration: 0.8,
                autoAlpha: 1,
                ease: 'power4.out',
            }, 0.7);
        }

        this.$('bullet')[this.prevIndex].classList.remove('swiper-pagination-bullet-active');
        this.$('bullet')[this.currentIndex].classList.add('swiper-pagination-bullet-active');
    }

    _handleGesture(touchstartX, touchstartY, touchendX, touchendY) {
        const delx = touchendX - touchstartX;
        const dely = touchendY - touchstartY;
        if(Math.abs(delx) > Math.abs(dely)){
            if(delx > 0) return "right"
            else return "left"
        }
        else if(Math.abs(delx) < Math.abs(dely)){
            if(dely > 0) return "down"
            else return "up"
        }
        else return "tap"
    }

}
