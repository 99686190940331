import { module as m } from 'modujs';
import {StoreSingleton} from "../../singletons/store";

export default class extends m {
    constructor(m) {
        super(m);
    }

    init() {
        if(StoreSingleton.isMobile) {
            this.el.addEventListener('click', () => {
                if(!this.el.querySelector('.language__list').classList.contains('active'))
                    this.el.querySelector('.language__list').classList.add('active')
                else
                    this.el.querySelector('.language__list').classList.remove('active')
            });
        }
    }
}
