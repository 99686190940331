import { module as m } from 'modujs';

export default class extends m {
    constructor(m) {
        super(m);

        // DOM
        this.dom = {};
        this.dom.selectOffice = this.el.querySelector('#office');
        this.dom.selectSubjects = this.el.querySelector('#subject');
        this.dom.inputFile = this.el.querySelector('#form-attachment');
        this.dom.inputFileName = this.el.querySelector('#contact .section-content .column-7 .file .file-name');

        // FUNC
        this._setCurrentSubject = this._setCurrentSubject.bind(this);
    }

    init() {

        // OFFICE
        this.dom.selectOffice.addEventListener('change', this._setCurrentSubject);

        // FILE
        this.dom.inputFile.addEventListener('change', () => {
            let _fileName = this.dom.inputFile.value.replace('C:\\fakepath\\', '');
            this.dom.inputFileName.innerHTML = _fileName;
        });

        this._setCurrentSubject();
    }

    _setCurrentSubject() {
        const _value = this.dom.selectOffice.value;
        let _list = null;

        // Remove all options
        while (this.dom.selectSubjects.options.length > 0) {
            this.dom.selectSubjects.remove(0);
        }

        if(_value === 'Rigby Capital UK') {
            _list = SUBJECTS_UK;
        } else {
            _list = SUBJECTS_FR;
        }

        _list.forEach(item => {
            const _op = document.createElement('option');
            _op.value = item.label;
            _op.text = item.label;
            this.dom.selectSubjects.add(_op, null);
        });
    }
}
