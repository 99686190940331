import { module as m } from 'modujs';
import { html, main, transition } from './../../utils/environment';
import barba from '@barba/core';
import gsap from 'gsap';
import LocomotiveScroll from 'locomotive-scroll';
import imagesLoaded from 'imagesloaded';
import {StoreSingleton} from "../../singletons/store";

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.lastY = 0;
        this.scroll = null;
        this.scrollArgs = {
            el: main,
            smooth: true,
            offset: ['10%'],
            lerp: 0.15,
            firefoxMultiplier: 70,
            reloadOnContextChange: true,
            tablet: {
                breakpoint: 1024
            }
        };
    }

    init() {

        // First scroll instance
        this.createScroll();

        barba.hooks.afterLeave((data) => {
            if(this.scroll)
                this.scroll.destroy();
        });

        barba.hooks.enter((data) => {
            
            if(StoreSingleton.isMobile)
                this.call('closeMenu', {}, 'MenuMobile');

            this.call('destroy', data.current.container, 'app');
            this.call('update', data.next.container, 'app');

            // Remove
            html.classList.remove('has-dom-ready');
        });

        barba.hooks.after((data) => {

            // SCROLL
            window.scroll(0, 0);

            setTimeout(() => {
                //this.startPage(data.next.container.querySelector('[data-page]').dataset);
                this.createScroll();
                html.classList.add('has-dom-ready');
            }, 100);
        });

        barba.init({
            transitions: [
            {
                name: 'opacity-transition',
                leave(data) {
                    const _tl = gsap.timeline({
                        onStart: () => {
                            transition.style.display = 'block';
                        }
                    });
                    _tl.set('#transition .panel', {
                        scaleX: 0,
                    });
                    _tl.to('#transition .panel', {
                        duration: 1.4,
                        scaleX: 1,
                        skewX: -25,
                        ease: 'power4.inOut'
                    });
                    return _tl
                },
                after(data) {
                    const _tl = gsap.timeline({
                        onComplete: () => {
                            transition.style.display = 'none';
                        }
                    });
                    _tl.to('#transition .panel', {
                        duration: 1.4,
                        scaleX: 0,
                        skewX: 0,
                        ease: 'power4.inOut'
                    });
                    return _tl
                }
            }, {
                name: 'introduction',
                beforeOnce(data) {
                    console.log('beforeOnce');
                },
                once(data) {
                    console.log('once');
                },
                afterOnce(data) {
                    const _tl = gsap.timeline({
                        onComplete: () => {
                            transition.style.display = 'none';
                        }
                    });
                    _tl.to('#transition .panel', {
                        duration: 1.4,
                        scaleX: 0,
                        skewX: 0,
                        ease: 'power4.inOut'
                    });
                    return _tl
                }
            }]
        });

        setTimeout(() => {
            //this.startPage(data.next.container.querySelector('[data-page]').dataset);
            this.scroll.update();
            html.classList.add('has-dom-ready');
        }, 450);
    }

    /**
     * Create scroll
     */
    createScroll() {
        this.scroll = new LocomotiveScroll(this.scrollArgs);

        this.scroll.on('call', (func, way, elt) => {
            console.log(func[0], {elt, way, params: func[1]}, func[2]);
            this.call(func[0], {elt, way, params: func[1]}, func[2], func[3]);
        });

        this.scroll.on('scroll', (args) => {
            window.deadwater.scroll = args;

            // HEADER
            const _direction = args.scroll.y >= this.lastY ? 1 : -1;
            if(_direction === 1 && args.scroll.y > 100)
                this.call('hide', {}, 'Header');
            else
                this.call('show', {}, 'Header');

            this.lastY = args.scroll.y;
        });

        imagesLoaded( document.body, (instance) => {
            console.log('LOAD IMAGE');
            this.update();
        });
    }

    startPage(i__modules) {
        if(i__modules) {
            const _pageClass = findValueByPrefix(i__modules, 'module');
            this.call('start', {}, _pageClass);
        }
    }

    /**
     * UPDATE
     */
    update() {
        this.scroll.update();
    }

    scrollTo(i__target) {
        this.scroll.scrollTo(i__target, {
            offset: -200
        });
    }
}


/*
import { module as m } from 'modujs';
import modularLoad from 'modularload';
import { html } from './../../utils/environment';
import gsap from 'gsap';
import { findValueByPrefix } from "./../../utils/utils"
import {StoreSingleton} from "../../singletons/store";

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.isReady = false;
        this.currentLoadImage = null;
    }

    init() {

        const load = new modularLoad({
            enterDelay: 0,
            reloadOnContextChange: true
        });

        /!**
         * LOADING
         *!/
        load.on('loading', (transition, oldContainer) => {
            console.log("**** LOADING ****", transition, oldContainer);
        });

        /!**
         * LOADED
         *!/
        load.on('loaded', (transition, oldContainer, newContainer) => {
            console.log("**** LOADED ****", transition, oldContainer, newContainer);

            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');

            // Remove
            html.classList.remove('has-dom-ready');
        });

        /!**
         * READY
         *!/
        load.on('ready', (transition, newContainer) => {
            console.log("**** READY ****", transition, newContainer);

            window.scroll(0, 0);

            setTimeout(() => {
                this.startPage(newContainer.querySelector('[data-page]').dataset);
                html.classList.add('has-dom-ready');
            }, 100);
        });

        /!**
         * ALL IMAGES LOADED
         *!/
        load.on('images', () => {
            console.log("**** ALL IMAGES OK ****");
        });
    }

    /!**
     * Start page
     * @param i__data
     *!/
    startPage(i__modules) {
        if(i__modules) {
            const _pageClass = findValueByPrefix(i__modules, 'module');
            this.call('start', {}, _pageClass);
        }
    }
}
*/
