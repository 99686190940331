import { module as m } from 'modujs';
import gsap from "gsap";

export default class extends m {
    constructor(m) {
        super(m);

        // DOM
        this.dom = {};
        this.currentSub = null;
        this.dom.items = this.el.querySelectorAll('.menu-mobile__main-list ul li.menu-item-has-children');

        // EVENTS
        this.events = {
            click: {
                'close': 'closeMenu',
                'back': 'backSubMenu'
            }
        }

    }

    init() {

        this.dom.items.forEach(item => {
            item.addEventListener('click', () => {
                const _id = item.dataset.menuid;
                this.currentSub = this.el.querySelector('.menu-mobile__depth-1.sub-' + _id);
                gsap.to(this.currentSub, {
                    duration: 0.6,
                    x: 0,
                    ease: 'power4.inOut'
                });
                gsap.to(this.$('back')[0], {
                    duration: 0.6,
                    autoAlpha: 1,
                    ease: 'power4.out'
                });
            });
        })

    }

    openMenu() {
        gsap.to(this.el, {
            duration: 0.6,
            autoAlpha: 1,
            ease: 'power4.out'
        });
    }

    closeMenu() {
        gsap.to(this.el, {
            duration: 0.6,
            autoAlpha: 0,
            ease: 'power4.out',
            onComplete: () => {
                if(this.currentSub) {
                    gsap.set(this.currentSub, {
                        x: '100%',
                    });
                }
                gsap.set(this.$('back')[0], {
                    autoAlpha: 0
                });
                this.currentSub = null;
            }
        });
    }

    backSubMenu() {
        gsap.to(this.currentSub, {
            duration: 0.6,
            x: '100%',
            ease: 'power4.inOut'
        });
        gsap.to(this.$('back')[0], {
            duration: 0.6,
            autoAlpha: 0,
            ease: 'power4.out'
        });
    }
}
