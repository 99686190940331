import { module as m } from 'modujs';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import {StoreSingleton} from "../../singletons/store";

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.swiper = null;
        this.items = this.el.querySelectorAll('.swiper-slide');
        this.wrapper = this.el.querySelector('.swiper-wrapper');
    }

    init() {
        this.swiper = new Swiper(this.$('container')[0], {
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: this.el.querySelector('.navigation .right'),
                prevEl: this.el.querySelector('.navigation .left'),
            },
            pagination: {
                el: this.el.querySelector('.swiper-pagination'),
                type: 'bullets',
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3
                }
            }
        });

        if(this.items.length < 3 && !StoreSingleton.isMobile) {
            this.wrapper.style.justifyContent = 'center';
        }
    }
}
