import { module as m } from 'modujs';
import axios from 'axios';
import gsap from 'gsap';
import {StoreSingleton} from "../../singletons/store";

export const FORM_EVENT = {
    'SUCCESS': 'form_event__success'
};

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.isProcessing = false;
        this.ajaxUrl = this.el.getAttribute('action');

        // DOM
        this.inputs = this.el.querySelectorAll('.required');
        this.dom = {};
        this.dom.validationMessage = document.querySelector('#contact .success-message');
        this.dom.loader = document.querySelector('#contact .loader');

        // EVENTS
        this.events = {
            'click': {
                'submit': '_validateForm'
            }
        }
    }

    /**
     * INIT
     */
    init() {

        if(this.el.classList.contains('form-media')) {
            axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        }
    }

    /**
     * RESET
     * @param resetFormMessage
     */
    reset(resetFormMessage = false) {

        this.el.reset();

        for(let i = 0; i < this.inputs.length; i++) {
            let input = this.inputs[i];
            input.parentNode.parentNode.classList.remove('error');
        }

        if(resetFormMessage) {
            this.$('message')[0].innerHTML = '';
        }
    }

    /**
     * VALIDATE FORM
     * @param event
     * @private
     */
    _validateForm(event) {
        event.preventDefault();

        if(!this.isProcessing) {
            this.isProcessing = true;
            this.$('message')[0].classList.remove('success');
            let formError = 0; // 1 -- email // 2 -- checkbox // 3 -- empty // 4 -- too short

            // TRIM INPUT
            for(let i = 0; i < this.inputs.length; i++) {
                let input = this.inputs[i];
                input.parentNode.parentNode.classList.remove('error');
                if(input.value.trim() == '' && !input.classList.contains('can-be-empty')) {
                    input.parentNode.parentNode.classList.add('error');
                    formError = 3;
                }
            }

            // INPUT TYPE
            if(formError === 0) {
                for(let i = 0; i < this.inputs.length; i++) {
                    let input = this.inputs[i];
                    if(input.classList.contains('email')) {
                        if(!this._isValidEmailAddress(input.value.trim())) {
                            input.parentNode.parentNode.classList.add('error');
                            formError = 1;
                            break;
                        }
                    } else if(input.classList.contains('checkbox')) {
                        if(!input.checked) {
                            input.parentNode.parentNode.classList.add('error');
                            formError = 2;
                            break;
                        }
                    } else if(input.classList.contains('password')) {
                        if(input.classList.contains('can-be-empty') && input.value.trim() != '') {
                            if (input.value.trim().length < 8) {
                                input.parentNode.parentNode.classList.add('error');
                                formError = 4;
                                break;
                            }
                        } else if(!input.classList.contains('can-be-empty')) {
                            if (input.value.trim().length < 8) {
                                input.parentNode.parentNode.classList.add('error');
                                formError = 4;
                                break;
                            }
                        }
                    }
                }
            }

            if(formError !== 0) {
                switch (formError) {
                    case 1:
                        if(CURRENT_LANG === "fr")
                            this.$('message')[0].innerHTML = "Votre email n'est pas valide";
                        else
                            this.$('message')[0].innerHTML = "Your email is not valid";
                        break;
                    case 2:
                        if(CURRENT_LANG === "fr")
                            this.$('message')[0].innerHTML = "Vous devez accepter la politique de confidentialité";
                        else
                            this.$('message')[0].innerHTML = "You must accept the privacy policy";
                        break;
                    case 3:
                        if(CURRENT_LANG === "fr")
                            this.$('message')[0].innerHTML = "Tous ces champs sont obligatoires";
                        else
                            this.$('message')[0].innerHTML = "All these fields are mandatory";
                        break;
                    case 4:
                        this.$('message')[0].innerHTML = "Votre mot de passe doit comporter au moins 8 caractères";
                        break;
                }
                this.isProcessing = false;
            } else {
                this._submitForm();
            }
        }
    }

    /**
     * SUBMIT FORM
     * @private
     */
    _submitForm() {
        this.$('message')[0].innerHTML = '';
        let formData = new FormData(this.el);

        gsap.to(this.dom.loader, {
            duration: 0.6,
            autoAlpha: 1,
            ease: 'power4.out'
        });

        axios({
            method: 'post',
            url: this.ajaxUrl,
            data: formData
        })
            .then((response) => {
                console.log(response);
                switch (response.data.code) {
                    case 200:
                        this.$('message')[0].classList.add('success');
                        gsap.to(this.dom.loader, {
                            duration: 0.6,
                            autoAlpha: 0,
                            ease: 'power4.out'
                        });
                        gsap.to(this.dom.validationMessage, {
                            duration: 0.6,
                            autoAlpha: 1,
                            ease: 'power4.out'
                        });

                        if(StoreSingleton.isMobile) {
                            this.call('scrollTo', '#contact .section-content .column-7 .success-message .text-content', 'Load');
                        }
                        /*if(response.data.message)
                            this.$('message')[0].innerHTML = response.data.message;*/
                        this.reset();
                        break;
                    case 300:

                        gsap.to(this.dom.loader, {
                            duration: 0.6,
                            autoAlpha: 0,
                            ease: 'power4.out'
                        });

                        if(CURRENT_LANG === "fr")
                            this.$('message')[0].innerHTML = "Fichier invalide";
                        else
                            this.$('message')[0].innerHTML = "Invalid file";
                        break;
                    case 404:

                        gsap.to(this.dom.loader, {
                            duration: 0.6,
                            autoAlpha: 0,
                            ease: 'power4.out'
                        });

                        this.$('message')[0].innerHTML = response.data.message;
                        break;
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
                this.isProcessing = false;
            });
    }

    /**
     * VALIDATE EMAIL ADDRESS
     * @param emailAddress
     * @returns {boolean}
     * @private
     */
    _isValidEmailAddress(emailAddress) {
        let pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
        return pattern.test(emailAddress);
    };
}