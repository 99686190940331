import { module as m } from 'modujs';

export default class extends m {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                'open': 'openMenu'
            }
        }
    }

    init() {
        console.log("HEADER MOBILE");
    }

    openMenu() {
        this.call('openMenu', {}, 'MenuMobile');
    }
}
