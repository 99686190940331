import { module as m } from 'modujs';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

Swiper.use([Navigation, Pagination]);

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.swiper = null;
    }

    init() {
        this.swiper = new Swiper(this.$('container')[0], {
            slidesPerView: 1,
            spaceBetween: 30,
            navigation: {
                nextEl: this.el.querySelector('.navigation .right'),
                prevEl: this.el.querySelector('.navigation .left'),
            },
            pagination: {
                el: this.el.querySelector('.swiper-pagination'),
                type: 'bullets',
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3
                }
            }
        });
    }
}
