import { module as m } from 'modujs';

export default class extends m  {
    constructor(m) {
        super(m);
    }

    /**
     * INIT
     */
    init() {
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    /**
     * START
     */
    start() {}

    /**
     * DESTROY
     */
    destroy() {}
}
