import { module as m } from 'modujs';
import Swiper from 'swiper';
import 'swiper/css';

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.swiper = null;
    }

    init() {
        this.swiper = new Swiper(this.$('container')[0], {
            slidesPerView: 1,
            loop: true,
            spaceBetween: 20,
            breakpoints: {
                1023: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            }
        });
    }
}
