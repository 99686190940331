import { module as m } from 'modujs';
import Swiper from 'swiper';
import 'swiper/css';

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.swiper = null;

    }

    init() {

        this.swiper = new Swiper(this.$('container')[0], {
            slidesPerView: 1,
            centeredSlides: true,
            spaceBetween: 30,
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                    centeredSlides: false
                }
            }
        });
    }
}
