import { module as m } from 'modujs';
import ASScroll from '@ashthornton/asscroll'
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class extends m {
    constructor(m) {
        super(m);
    }

    init() {

        window.deadwater.scroll.instance = new ASScroll({
            ease: 0.12,
            disableRaf: true
        });
        gsap.ticker.add(window.deadwater.scroll.instance.update)

        ScrollTrigger.defaults({
            scroller: window.deadwater.scroll.instance.containerElement
        })

        ScrollTrigger.scrollerProxy(window.deadwater.scroll.instance.containerElement, {
            scrollTop(value) {
                return arguments.length ? window.deadwater.scroll.instance.currentPos = value : window.deadwater.scroll.instance.currentPos;
            },
            getBoundingClientRect() {
                return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight }
            }
        });

        window.deadwater.scroll.instance.on("update", ScrollTrigger.update);
        window.deadwater.scroll.instance.on('scroll', scrollPos => window.deadwater.scroll.scrollY = scrollPos);
        ScrollTrigger.addEventListener("refresh", window.deadwater.scroll.instance.resize);

        window.deadwater.scroll.instance.enable();
    }
}
